import styled from 'styled-components'

export const ArticleCardsSwiperWrapper = styled.div`
  position: relative;
  margin-right: -36px;
  max-width: 100vw;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    margin-right: -90px;
  }

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    margin: auto;
    padding: 0 73px;
  }

  @media (min-width: 1720px) {
    max-width: 1542px;
  }
`

export const Viewport = styled.div`
  overflow: hidden;
  padding-left: 36px;
  padding-right: 16px;

  @media (${({ theme }) => theme.mediaQueries.xl}) {
    padding: 0 16px;
  }
`

export const Container = styled.div`
  display: flex;
  gap: 16px;
  padding-bottom: 36px;
`

export const Slide = styled.div`
  min-width: calc(100% - 8px);
  max-width: 100%; /* Prevent from growing larger than viewport */
  max-height: 826px;

  @media (${({ theme }) => theme.mediaQueries.md}) {
    min-width: calc(50% - 8px);
  }
`
