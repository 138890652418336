// React
import { useState, useEffect, useCallback } from 'react'

// React components
import ArticleCard from '../ArticleCard/ArticleCard'
import SwiperButton from '../Swiper/SwiperButton'

// Styled Components
import {
  ArticleCardsSwiperWrapper,
  Viewport,
  Container,
  Slide,
} from './ArticleCardsSwiper.styles'

// Embla Carousel
import useEmblaCarousel from 'embla-carousel-react'

// Artsy Fresnel
import { Media, MediaContextProvider } from '../../utils/media'

// Types
import { Api } from '../../types/api'
import { articleLink } from '../../utils/links'

type Props = {
  data: Api.Article[]
  career?: boolean
}

const ArticleCardsSwiper = ({ data, career }: Props) => {
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false)

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    containScroll: 'trimSnaps',
  })

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setPrevBtnEnabled(emblaApi.canScrollPrev())
    setNextBtnEnabled(emblaApi.canScrollNext())
  }, [emblaApi])

  useEffect(() => {
    if (!emblaApi) return
    onSelect()
    emblaApi.on('select', onSelect)
  }, [emblaApi, onSelect])

  useEffect(() => {
    if (emblaApi) {
      setPrevBtnEnabled(emblaApi.canScrollPrev())
      setNextBtnEnabled(emblaApi.canScrollNext())
    }
  }, [emblaApi])

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev()
  }, [emblaApi])

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext()
  }, [emblaApi])

  if (data.length < 1) {
    return null
  }

  return (
    <ArticleCardsSwiperWrapper>
      <Viewport ref={emblaRef}>
        <Container>
          {data.map((article, index) => {
            return (
              <Slide key={index}>
                <ArticleCard
                  key={index}
                  title={article.attributes.title}
                  date={article.attributes.date}
                  text={article.attributes.perex}
                  href={articleLink(article, career)}
                  imageUrl={
                    article.attributes.image.data?.attributes.url ||
                    '/images/article-placeholder.webp'
                  }
                  imageAlt={article.attributes.title}
                />
              </Slide>
            )
          })}
        </Container>
      </Viewport>
      <MediaContextProvider disableDynamicMediaQueries>
        <Media greaterThanOrEqual='lg'>
          <SwiperButton
            variant='left'
            onClick={scrollPrev}
            hide={!prevBtnEnabled}
          />
          <SwiperButton
            variant='right'
            onClick={scrollNext}
            hide={!nextBtnEnabled}
          />
        </Media>
      </MediaContextProvider>
    </ArticleCardsSwiperWrapper>
  )
}
export default ArticleCardsSwiper
