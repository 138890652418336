import styled, { css } from 'styled-components'

// Types
import { ButtonWrapperProps } from './Button.types'

// Framer Motion
import { motion } from 'framer-motion'

const formCss = css`
  width: 100%;
  justify-content: center;
`

const inactiveCss = css`
  pointer-events: none;
`

const variants = {
  contained: css`
    color: ${({ theme }) => theme.colors.button.contained.text};
    background: ${({ theme }) => theme.colors.button.contained.background};
    border-color: ${({ theme }) => theme.colors.button.contained.border};

    &:hover,
    &:active {
      border: none;
      padding: 16px 19px;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.button.contained.textHover};
      background: ${({ theme }) =>
        theme.colors.button.contained.backgroundHover};
    }

    &:active {
      background: ${({ theme }) =>
        theme.colors.button.contained.backgroundActive};
    }
  `,
  outlined: css`
    color: ${({ theme }) => theme.colors.button.outlined.text};
    background: ${({ theme }) => theme.colors.button.outlined.background};
    border-color: ${({ theme }) => theme.colors.button.outlined.border};

    &:hover {
      color: ${({ theme }) => theme.colors.button.outlined.textHover};
      background: ${({ theme }) =>
        theme.colors.button.outlined.backgroundHover};
    }

    &:active {
      color: ${({ theme }) => theme.colors.button.outlined.textActive};
      background: ${({ theme }) =>
        theme.colors.button.outlined.backgroundActive};
      border: none;
      padding: 16px 19px;
    }
  `,
  outlinedSecondary: css`
    color: ${({ theme }) => theme.colors.button.outlinedSecondary.text};
    background: ${({ theme }) =>
      theme.colors.button.outlinedSecondary.background};
    border-color: ${({ theme }) =>
      theme.colors.button.outlinedSecondary.border};
    backdrop-filter: blur(50px);

    &:hover,
    &:active {
      border: none;
      padding: 16px 19px;
    }

    &:hover {
      color: ${({ theme }) => theme.colors.button.outlinedSecondary.text};
      background-color: ${({ theme }) =>
        theme.colors.button.outlinedSecondary.backgroundHover};
    }

    &:active {
      background-color: ${({ theme }) =>
        theme.colors.button.outlinedSecondary.backgroundActive};
    }
  `,
}

export const ButtonWrapper = motion(styled.button<ButtonWrapperProps>`
  cursor: pointer;
  display: flex;
  width: fit-content;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
  ${({ form }) => form && formCss};
  ${({ inactive }) => inactive && inactiveCss}
  padding: 15px 18px;
  border: 1px solid transparent;
  border-radius: 66px;
  font-size: ${({ theme }) => (18 - 2) / theme.fontSizes.root + 'rem'};
  ${({ variant }) => variant === 'contained' && variants.contained};
  ${({ variant }) => variant === 'outlined' && variants.outlined};
  ${({ variant }) =>
    variant === 'outlinedSecondary' && variants.outlinedSecondary};
  text-decoration: none;

  ${({ variant, active }) =>
    variant === 'contained' &&
    active &&
    css`
      color: ${({ theme }) => theme.colors.button.contained.textHover};
      background: ${({ theme }) =>
        theme.colors.button.contained.backgroundHover};
    `};
  ${({ variant, active }) =>
    variant === 'outlined' &&
    active &&
    css`
      color: ${({ theme }) => theme.colors.button.outlined.textHover};
      background: ${({ theme }) =>
        theme.colors.button.outlined.backgroundHover};
    `};
  ${({ variant, active }) =>
    variant === 'outlinedSecondary' &&
    active &&
    css`
      color: ${({ theme }) => theme.colors.button.outlinedSecondary.text};
      background-color: ${({ theme }) =>
        theme.colors.button.outlinedSecondary.backgroundHover};
    `};

  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline-offset: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors.grey120};
    color: ${({ theme }) => theme.colors.grey80};
  }
`)

export const Text = styled.span`
  color: currentColor;
`
