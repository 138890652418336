// React components (svgs)
import SwiperArrow from '../../svgs/SwiperArrow'

// Styled Components
import { SwiperButtonWrapper } from './SwiperButton.styles'

// Types
import { SwiperButtonProps } from './SwiperButton.types'

const SwiperButton = ({
  onClick,
  variant = 'left',
  hide = false,
  service = false,
}: SwiperButtonProps) => {
  return (
    <SwiperButtonWrapper
      service={service}
      aria-label={`${
        variant === 'right' ? 'Pravé' : 'Levé'
      } tlačítko pro posun`}
      variant={variant}
      onClick={onClick}
      hide={hide}
    >
      <SwiperArrow variant={variant} />
    </SwiperButtonWrapper>
  )
}
export default SwiperButton
