import styled from 'styled-components'

// Types
import { SwiperArrowWrapperProps } from './SwiperArrow.types'

export const SwiperArrowWrapper = styled.svg<SwiperArrowWrapperProps>`
  transform: ${({ variant }) => variant === 'right' && 'rotate(180deg)'};
`

export const Main = styled.g`
  ${SwiperArrowWrapper}:hover & {
    transition: fill 0.3s;
    fill: ${({ theme }) => theme.colors.red100};
  }

  ${SwiperArrowWrapper}:active & {
    /* fill: url(#linear-gradient); */
  }
`

export const Chevron = styled.g`
  ${SwiperArrowWrapper}:hover & path {
    transition: stroke 0.3s;
    stroke: ${({ theme }) => theme.colors.white};
  }

  ${SwiperArrowWrapper}:active & {
    stroke: ${({ theme }) => theme.colors.white};
  }
`
