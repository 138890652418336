// React
import React from 'react'

// React components (svg)
import ArrowIcon from '../svgs/icons/ArrowIcon'
import CheckIcon from '../svgs/icons/CheckIcon'

// Styled Components
import { ButtonWrapper, Text } from './Button.styles'

// Types
import { ButtonProps } from './Button.types'

const Button = React.forwardRef(
  (
    {
      children,
      variant,
      arrow,
      link,
      href,
      type = 'button',
      title,
      ariaLabel,
      rel,
      download,
      active,
      target,
      onClick,
      disabled = false,
      form = false,
      check = false,
    }: ButtonProps,
    ref: any,
  ) => {
    return (
      <ButtonWrapper
        type={type}
        variant={variant}
        ref={ref}
        href={href}
        onClick={onClick}
        as={link ? 'a' : 'button'}
        title={title}
        aria-label={ariaLabel}
        rel={rel}
        download={download}
        target={target}
        active={active}
        disabled={disabled}
        form={form}
        animate={{ maxWidth: form ? (check ? 62 : 150) : 'auto' }}
        inactive={form && check}
      >
        {check ? <CheckIcon /> : <Text>{children}</Text>}

        {arrow && <ArrowIcon />}
      </ButtonWrapper>
    )
  },
)

Button.displayName = 'Button'

export default Button
