// Styled Components
import { SwiperArrowWrapper, Main, Chevron } from './SwiperArrow.styles'

// Types
import { SwiperArrowProps } from './SwiperArrow.types'

const SwiperArrow = ({ variant = 'left' }: SwiperArrowProps) => {
  return (
    <SwiperArrowWrapper
      xmlns='http://www.w3.org/2000/svg'
      width='143.328'
      height='71.502'
      viewBox='0 0 143.328 71.502'
      variant={variant}
    >
      <defs>
        <linearGradient
          id='linear-gradient'
          x1='1'
          x2='-0.235'
          y1='1.749'
          y2='-0.667'
          gradientUnits='objectBoundingBox'
        >
          <stop offset='0' stopColor='#d90017'></stop>
          <stop offset='1' stopColor='#6d000c'></stop>
        </linearGradient>
      </defs>
      <g data-name='prepinani doleva' transform='rotate(180 71.664 35.751)'>
        <Main
          fill='none'
          stroke='#d90017'
          strokeWidth='1'
          data-name='Rectangle 482'
          transform='rotate(90 71.664 71.664)'
        >
          <rect
            width='71.502'
            height='143.328'
            stroke='none'
            rx='35.751'
          ></rect>
          <rect
            width='70.502'
            height='142.328'
            x='0.5'
            y='0.5'
            rx='35.251'
          ></rect>
        </Main>
        <Chevron data-name='Group 52' transform='rotate(-90 -61.321 1277.824)'>
          <path
            fill='none'
            stroke='#d90017'
            strokeWidth='1.5'
            d='M1161 1430.166l19.704 19.704 19.358-19.358'
            data-name='Path 8'
          ></path>
        </Chevron>
      </g>
    </SwiperArrowWrapper>
  )
}
export default SwiperArrow
