import styled, { css } from 'styled-components'

// Types
import {
  ImageWrapperProps,
  ArticleWrapperProps,
  ContainerProps,
} from './ArticleCard.types'

export const ArticleCardWrapper = styled.div<ArticleWrapperProps>`
  height: 100%;
  display: flex;
  flex-direction: column;

  ${props =>
    props.highlighted &&
    css`
      @media (${({ theme }) => theme.mediaQueries.md}) {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        max-width: initial;
      }

      @media (${({ theme }) => theme.mediaQueries.lg}) {
        max-width: 1920px;
        width: 100%;
        margin-left: -180px;
      }
    `}
`

export const ImageWrapper = styled.div<ImageWrapperProps>`
  cursor: pointer;
  position: relative;
  z-index: 3;
  height: 170px;

  img {
    transform: scale(1);
    transition: transform 0.2s ease-out;
  }

  &:hover {
    img {
      transform: scale(1.05);
    }
  }

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    height: 338px;
  }

  ${props =>
    props.highlighted &&
    css`
      height: 295px;
      z-index: 1;
      margin-left: -36px;
      margin-right: -36px;
      margin-bottom: -30px;

      @media (${({ theme }) => theme.mediaQueries.md}) {
        width: 439px;
        height: 392px;
        min-width: 200px;
        margin-left: initial;
        margin-right: -60px;
        margin-bottom: initial;
      }

      @media (${({ theme }) => theme.mediaQueries.lg}) {
        height: 886px;
        max-width: 1228px;
        width: 100%;
        min-width: 450px;
        margin-right: -260px;
      }
    `};
`

export const Date = styled.div``

export const ButtonWrapper = styled.div`
  align-self: flex-end;
  margin-top: auto;
`

export const Text = styled.div`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 63px;

  p {
    display: inline;
  }

  p::after {
    content: ' \A\A';
    white-space: pre;
  }

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    -webkit-line-clamp: 4;
    max-height: 99px;
  }
`

export const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: 2;
  flex-grow: 1;
  min-height: 340px;
  background: ${({ theme }) => theme.colors.articleCard.background};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 30px;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.16);

  @media (${({ theme }) => theme.mediaQueries.lg}) {
    padding: 55px;
  }

  ${props =>
    props.highlighted &&
    css`
      flex-grow: initial;

      @media (${({ theme }) => theme.mediaQueries.md}) {
        max-width: 305px;
      }

      @media (${({ theme }) => theme.mediaQueries.lg}) {
        max-width: 674px;
      }
    `};
`
