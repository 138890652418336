import {
  compose,
  map,
  trim,
  split,
  reduce,
  splitAt,
  match,
  length,
  last,
} from 'ramda'

export const renderStrongs = compose(
  ([left, right]: string[]) => (
    <>
      {trim(left || '')}
      <strong>{right || ''}</strong>
    </>
  ),
  map(reduce((a, b) => `${a} ${b}`, '')),
  res => splitAt(Math.floor(res.length / 2), res),
  split(' '),
)

const Dummy = ({ children }: any) => children

type SplitByType = {
  by?: string
  line: string
  Left?: any
  Right?: any
  removeSplitter?: boolean
}

export const splitTextBy = ({
  by = '/',
  line,
  Left = Dummy,
  Right = Dummy,
  removeSplitter,
}: SplitByType) =>
  line
    .split(by)
    .map((line, index) =>
      !index ? (
        <Left key={index}>{line}</Left>
      ) : (
        <Right key={index}>{`${removeSplitter ? '' : by}${line}`}</Right>
      ),
    )

export const replaceByR = (regExp: RegExp) => (
  matchFn: (a: string, b?: any) => any,
  noMatchFn: (a: any, b?: any) => any,
) => (text: string) => {
  const matches = match(regExp, text)
  const splitted = split(regExp, text)

  const res = []

  if (length(matches) < 1) {
    return [noMatchFn(text, 0)]
  }

  for (let i = 0; i < length(matches); i++) {
    if (splitted[i]) {
      res.push(noMatchFn(splitted[i], i))
    }
    res.push(matchFn(matches[i], i))
  }

  if (length(splitted) > length(matches) && last(splitted)) {
    res.push(noMatchFn(last(splitted), length(splitted) - 1))
  }

  return res
}
