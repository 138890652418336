import styled, { css } from 'styled-components'

// Types
import { SwiperButtonWrapperProps } from './SwiperButton.types'

export const SwiperButtonWrapper = styled.button<SwiperButtonWrapperProps>`
  display: ${({ hide }) => (hide ? 'none' : 'inline-block')};
  position: absolute;
  z-index: 2;
  top: calc(50% - 35px);
  cursor: pointer;
  width: fit-content;
  border: none;
  background: transparent;
  padding: 0;

  ${({ variant, service }) =>
    (variant === 'left' || !variant) &&
    css`
      right: initial;
      left: ${service ? '-60px' : '0px'};
    `};

  ${({ variant }) =>
    variant === 'right' &&
    css`
      left: initial;
      right: 0;
    `}
`
